@font-face {
  font-family: Lato-Regular;
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url("../public/fonts/LatoRegular.woff2") format("woff2");
}

@font-face {
  font-family: Lato-Bold;
  font-weight: 600;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url("../public/fonts/LatoBold.woff2") format("woff2");
}

@font-face {
  font-family: Patrick-Hand-Regular;
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
  src: url("../public/fonts/PatrickHandRegular.woff2") format("woff2");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}
